<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="px-2">
        <p-input-text
          type="text"
          v-model="listName"
          placeholder="Movie or Book Name"
        />
      </div>
      <div class="px-2">
        <ActorSearch @actor-selected="actorSelected" />
      </div>
      <AddActorDialog
        :visible="!!selectedActor"
        :actor="selectedActor"
        @add-character="addCharacter"
        @close-dialog="closeDialog"
      />
    </div>
    <div
      id="actor-list"
      ref="captureDiv"
      class="row justify-content-center mt-4"
    >
      <p-card id="actor-cards-container" width="500">
        <template #title>{{ listName }}</template>
        <template #content>
          <div class="row g-1">
            <p-card
              class="actor-card"
              style=""
              v-for="character in characters"
              :key="character.actor.id"
            >
              <template #header>
                <img
                  alt="Actor Image"
                  width="150"
                  class="rounded mx-auto d-block mt-3"
                  :src="proxyImageUrl(character.actor.profile_path)"
                />
              </template>
              <template #title>
                <h1>
                  {{ character.characterName }}
                </h1>
              </template>
              <template #subtitle>
                played by {{ character.actor.name }}
              </template>
            </p-card>
          </div>
        </template>
        <template v-if="characters.length > 0" #footer>
          <div>
            <p>created using fantasycasts.com</p>
          </div>
        </template>
      </p-card>
    </div>
    <div v-if="characters.length > 0" class="row justify-content-end mb-4">
      <p-button label="Save PNG" @click="captureToImage" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ActorSearch from "./ActorSearch.vue";
import AddActorDialog from "./AddActorDialog.vue";
import html2canvas from "html2canvas";

export default {
  components: {
    ActorSearch,
    AddActorDialog,
  },
  setup() {
    const listName = ref("Title goes here");
    const selectedActor = ref(null);
    const characters = ref([]);
    const captureDiv = ref(null);

    const actorSelected = (actor) => {
      selectedActor.value = actor;
    };

    const addCharacter = (character) => {
      characters.value.push(character);
      selectedActor.value = null; // Close the dialog after adding the character
    };

    const closeDialog = () => {
      selectedActor.value = null; // Close the dialog when the close-dialog event is emitted
    };

    const proxyImageUrl = (path) => {
      const baseUrl = `${process.env.VUE_APP_PROXY_URL}`; // Replace with your actual Netlify URL in production`;

      const imageUrl = `https://media.themoviedb.org/t/p/w600_and_h900_bestv2${path}`;
      console.log(imageUrl);

      return `${baseUrl}?url=${encodeURIComponent(imageUrl)}`;
    };

    const captureToImage = () => {
      html2canvas(captureDiv.value, {
        useCORS: true,
        proxy: `${process.env.VUE_APP_PROXY_URL}`,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = `${listName.value}-fantasy-cast.png`;
        link.click();
      });
    };

    return {
      listName,
      selectedActor,
      characters,
      captureDiv,
      actorSelected,
      addCharacter,
      closeDialog,
      captureToImage,
      proxyImageUrl,
    };
  },
};
</script>
